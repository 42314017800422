import { NgbPaginationConfig, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor } from '@angular/common';

import { ListActionHandlerBase } from '../../classes/list-handler.class';
import { SortedFilteredList } from '../../classes';
import { AppConstants } from '../../config';
import { PluralPipe } from '../../pipes/plural.pipe';


@Component({
    selector: 'layout-list-paging',
    templateUrl: './list-paging.component.html',
    imports: [NgIf, TranslateModule, FormsModule, NgFor, NgbPaginationModule, PluralPipe]
})
export class ListPagingComponent {
	@Input() showPageSize = true;
	@Input() list: SortedFilteredList<any, any>;
	@Input() listHandler: ListActionHandlerBase;

	@Output() pageSizeUpdate: EventEmitter<number> = new EventEmitter();
	@Output() pageNumberUpdate: EventEmitter<number> = new EventEmitter();

	pagingConfig: NgbPaginationConfig = AppConstants.PAGING_CONFIG_DEFAULT;
	pageSizes: number[] = AppConstants.LIST_PAGE_SIZES;

	onSetPageSize(select: EventTarget) { this.setPageSize(+(select as HTMLSelectElement).value) }
	setPageSize(pageSize) {
		this.pageSizeUpdate.emit(pageSize);
		if (this.listHandler) {
			this.listHandler.setPageSize(pageSize);
		}
	}

	setPageNumber(pageNumber: number) {
		this.pageNumberUpdate.emit(pageNumber);
		if (this.listHandler) {
			this.listHandler.setPageNumber(pageNumber);
		}
	}

	get pageStart(): number {
		return ((this.list.state.page - 1) * this.list.state.pageSize) + 1;
	}

	get pageEnd(): number {
		return ((this.list.state.page - 1) * this.list.state.pageSize) + this.list.paging.actualSize;
	}
}
