import { Component, InjectionToken, Input, OnChanges, SimpleChanges, computed, inject, input, signal } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import upperFirst from 'lodash/upperFirst';
import { Subscription, takeUntil } from 'rxjs';

import { PluralPipe } from '../../pipes';
import { UnsubscriberClass } from '../../classes/unsubscriber.class';

export const defaultFormFeedbackMessages = `
	afterNow
	alreadyUsedIdentifier
	amountLowerThanBalance:AmountShouldBeGreaterOrEqualToBalance
	atLeastOneContact
	beforeNow
	dunningGroupNameAlreadyUsed
	dunningScenarioNameAlreadyUsed
	dunningStepAlreadyExists
	dunningTemplateNameAlreadyUsed
	email
	emailAlreadyUsed
	exactInitialDateOrGreaterOrEqualsNow
	extradataUnavailableType
	extradataUniqueName
	frenchIban
	iban
	importUniqueColumnSeparator
	invalidCurrentPassword:WrongCurrentPassword
	invalidFunctionalId
	invalidIdentifier
	invalidVerificationCode:ValidVerificationCode
	mismatchedPasswords
	ngbDate:Format
	noEmptyString
	noWhitespaceRequired:NoWhitespace
	numberRequired:Number
	passwordAlreadyUsed
	pattern:Format
	rangeInconsistency
	rangesValues
	required
	validDate
	wrongIdentifier
`
.split(/\n/)
.map(s => s.trim())
.filter(Boolean)
.map(s => s.split(':'));

export const FORM_FEEDBACK_MESSAGES = new InjectionToken<string[][]>('FORM_FEEDBACK_MESSAGES', {
	providedIn: 'root',
	factory: () => defaultFormFeedbackMessages,
})

export const FORM_FEEDBACK_PREFIX = new InjectionToken<string>('FORM_FEEDBACK_PREFIX', {
  providedIn: 'root',
  factory: () => 'Forms.FieldErrorMessages',
})

@Component({
	selector: 'message',
	template: `<div role="error-message" class="invalid-feedback e-{{ rule }}"><ng-content /></div>`,
})
export class FeedbackMessageComponent {
	@Input() rule = 'unknown';
}

@Component({
    selector: 'form-control-feedback',
    templateUrl: './form-control-feedback.component.html',
    imports: [TranslateModule, FeedbackMessageComponent, PluralPipe]
})
export class FormControlFeedbackComponent extends UnsubscriberClass implements OnChanges {
	@Input() field: AbstractControl | null;
	messages = input({});

	fieldSubscription!: Subscription;

	simpleCases = inject(FORM_FEEDBACK_MESSAGES);
	labelPrefix = inject(FORM_FEEDBACK_PREFIX);

	knownTranslations: { [ruleName: string]: string } = {
		...this.simpleCases.reduce((acc, s) => ({
			...acc,
			[s[0]]: this.asKey(s[1] || s[0])
		}), {}),

		// Annoying exceptions
		minlength: this.asKey('MinLength'),
		maxlength: this.asKey('MaxLength'),
	};

	updateMSOL = signal({})
	msol = computed(() => this.updatedMessages())

	has(name: string): string | false {
		return this.field.hasError(name) ? name : false;
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.updateMSOL.set({});

		if (changes.field) {
			this.fieldSubscription?.unsubscribe();
			this.fieldSubscription = this.field.statusChanges
				.pipe(takeUntil(this.destroySubscriptions$))
				.subscribe(_ => this.updateMSOL.set({}))
		}
	}

	asKey(name: string) {
		return `${this.labelPrefix}.${upperFirst(name)}`;
	}

	private updatedMessages() {
		return {
			...this.field?.errors
				? Object.keys(this.field.errors)
					.reduce((acc, k) => ({ ...acc, [k]: this.asKey(k) }), {})
				: {},
			...this.knownTranslations,
			...this.messages(),
			...this.updateMSOL(),
		}
	}
}
