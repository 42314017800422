
import { Component, Input } from '@angular/core';
import { CurrencyCodeType } from '@aston/foundation';

import { IDebtorKPIs } from '../../models';

@Component({
	selector: 'debtor-kpis',
	templateUrl: './debtor-kpis.component.html',
	standalone: false
})
export class DebtorKpisComponent {
	@Input() data: IDebtorKPIs;
	@Input() currency: CurrencyCodeType;
}
