import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { CurrencyCodeType, SortedTableClass } from '@aston/foundation';

import { IDunningAction } from '../../models';
import { DunningActionListSortProp } from '../../enums';


@Component({
	selector: 'actions-list-table',
	templateUrl: './actions-list-table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})
export class ActionsListTableComponent extends SortedTableClass<IDunningAction, DunningActionListSortProp> {
	@Input() currency: CurrencyCodeType;

	sortCols = DunningActionListSortProp;
}
