import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ActionType, IAction, Loader } from '@aston/foundation';
import { Observable } from 'rxjs';
import { ICommentItem } from 'apps/debtor-portal/src/app/comments-module/models';

@Component({
		selector: 'dunning-accounting-discuss',
		templateUrl: './dunning-accounting-discuss.component.html',
		standalone: false
})
export class DunningAccountingDiscussComponent {
	@Input() title = 'Comments.Title';
	@Input() comments$: Observable<ICommentItem[]>;
	@Input() loader = Loader.empty();
	@Output() action: EventEmitter<IAction> = new EventEmitter()

	onSubmit(event: ICommentItem) {
		this.action.emit({ type: ActionType.SUBMIT, value: event });
	}

	onClose() {
		this.action.emit({ type: ActionType.CLOSE });
	}
}
