
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { AppBaseConfiguration } from '../app-base.configuration';

export interface IAmountPipeConfig {
	currency?: string;
	with?: 'code' | 'symbol' | 'symbol-narrow' | '';
	round?: number | boolean;
	suffix?: number | boolean;
	considerZeroAsNaN?: boolean;
	zeroAsInteger?: boolean;
	digits?: string;
}

export const defaultAmountPipeConfig: IAmountPipeConfig = {
	considerZeroAsNaN: false,
	currency: 'EUR',
	round: false,
	suffix: 0,
	with: '',
	zeroAsInteger: false,
	digits: undefined,
};

@Pipe({
	name: 'amount',
})
export class AmountPipe implements PipeTransform {
	constructor(private config: AppBaseConfiguration, private _translateService: TranslateService) {
	}

	private defaultConfig: IAmountPipeConfig = {
		...defaultAmountPipeConfig,
		currency: this.config.currency,
	};

	transform(value: number, config?: Partial<IAmountPipeConfig>): string {

		const configToUse = { ...this.defaultConfig, ...config };

		if (!configToUse.currency) {
			configToUse.currency = this.defaultConfig.currency;
		}
		if (isNaN(value) || value === null) {
			return '-';
		}
		if (configToUse.considerZeroAsNaN && Math.abs(value) < 0.001) {
			return '-';
		}

		// handle rounding value
		let amount = value
		let digitsInfo = configToUse.digits
		// FIXME: Sort this out
		// eslint-disable-next-line eqeqeq
		const useSuffix = configToUse.suffix != false && (value >= +configToUse.suffix || value <= -configToUse.suffix)
		if (configToUse.zeroAsInteger) {
			digitsInfo = '1.0'
		}
		if (useSuffix) {
			amount = roundNumber(value, configToUse);
			digitsInfo = getNumberOfDigits(value, configToUse);
		}
		let result = new CurrencyPipe(this._translateService.currentLang).transform(amount, configToUse.currency, configToUse.with, digitsInfo);

		// handle rounding suffix
		if (useSuffix) {
			result = appendToLastDigit(result, roundToletter(configToUse));
		}
		return result;
	}
}

function roundToletter({ round, suffix }: Partial<IAmountPipeConfig>) {
	return ['', 'K', 'm', 'M'][Math.floor(Math.log10(round ? +round : +suffix) / 3)]
}

function appendToLastDigit(input: string, replace: string) {
	return input
		.split('').reverse().join('')
		.replace(/(\d)/, `${replace}$1`)
		.split('').reverse().join('');
}

function roundNumber(value: number, { round, suffix }: Partial<IAmountPipeConfig>): number {
	if (!value) return 0;
	const magnitudes = [1, 1000, Math.pow(10, 6), Math.pow(10, 9)];
	const magnitude = magnitudes[Math.floor(Math.log10(round ? +round : +suffix) / 3)];

	if (value > magnitude && value < 10 * magnitude) {
		return Math.round(value * 10 / (round ? +round : +suffix)) / 10;
	} else {
		return Math.round(value / (round ? +round : +suffix));
	}
}

function getNumberOfDigits(value: number, { round, suffix }: Partial<IAmountPipeConfig>): string {
	if (!value) return '0';
	const magnitudes = [1, 1000, Math.pow(10, 6), Math.pow(10, 9)];
	const magnitude = magnitudes[Math.floor(Math.log10(round ? +round : +suffix) / 3)];

	const result = Math.round(value * 10 / (round ? +round : +suffix));

	return (value > magnitude && value < 10 * magnitude && result.toString()[1] !== '0') ? '1.1' : '1.0';
}
