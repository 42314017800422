<comment-item
  [comment]="comment"
  [canReply]="canReply"
  (showReply)="toggleReply(true)"
/>

@if (comment.children?.length) {
  <div class="comments-children d-flex flex-row" [class.comments-children-collapsed]="!showChildren">
    <div class="col-1 comments-children-toggle text-right" (click)="showChildren = !showChildren">
      @if (!showChildren) {
        <span>
            {{comment.children.length}} {{ 'DataList.Columns.Comment'
                | plural: comment.children.length
                | translate
                | lowercase
            }}
        </span>
      }
    </div>
    @if (showChildren) {
      <div class="col-11 comments-children-content">
        @for (
            icomment of comment.children;
            track icomment;
            let isLast = $last;
        ) {
          <comment-item-wrapper
            [comment]="icomment"
            [canReply]="isLast"
            [replyToCommentId]="comment.id"
            (newComment)="newComment.emit($event)"
          />
        }
      </div>
    }
  </div>
}
@if (canReply && doShowReply) {
  <div class="comment-reply-form ml-4 mb-2">
    <form-comments
      [showCancel]="true"
      (submitForm)="onNewSubComment($event, replyToCommentId || comment.id)"
      (cancelForm)="toggleReply(false)"
    />
  </div>
}
