
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConstants } from 'apps/debtor-portal/src/app/app.constants';
import { TranslateModule } from '@ngx-translate/core';
import { DateFormatPipe, TextEllipsisPipe } from '@aston/foundation';
import { UserAvatarComponent } from 'apps/debtor-portal/src/app/shared-module/components/user-avatar/user-avatar.component';

import { ICommentItem } from '../../models';


@Component({
    selector: 'comment-item',
    templateUrl: './comment-item.component.html',
    imports: [UserAvatarComponent, TranslateModule, DateFormatPipe, TextEllipsisPipe]
})
export class CommentItemComponent implements OnInit {
	@Input() comment: ICommentItem;
	@Input() truncateSize: number = AppConstants.DEFAULT_COMMENT_TRUNCATE;
	@Input() isCollapsed = true;
	@Input() canReply: boolean;

	@Output() showReply = new EventEmitter<void>();

	isTruncateNeeded = false;
	inlineContent: string;

	ngOnInit() {
		this.inlineContent = (this.comment?.content || '').replace(/\n/g, AppConstants.COMMENT_NEWLINE_WEIGHT);
		this.isTruncateNeeded = this.inlineContent.length > this.truncateSize;
	}

	onToggle() {
		this.isCollapsed = !this.isCollapsed;
	}

}
