import { Component } from '@angular/core';

@Component({
	selector: 'authentication-issue-page',
	templateUrl: './authentication-issue-page.component.html',
	standalone: false
})
export class AuthenticationIssuePageComponent {

}
