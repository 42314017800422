
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { onLine$ } from '../../functions';
import { UnsubscriberClass } from '../../classes/unsubscriber.class';
import { Loader } from '../../classes/loader.class';

@Component({
    selector: 'loader-spinner',
    templateUrl: './loader-spinner.component.html',
    styles: [],
    imports: [AsyncPipe, NgbAlertModule, TranslateModule]
})
export class LoaderSpinnerComponent extends UnsubscriberClass implements OnInit {
	@Input() model: Loader = new Loader(true);
	@Input() revertStyle = false; // Revert spinner color
	@Input() size = 'large';
	@Input() loadingMessage: string;
	@Input() errorMessage: string;
	@Input() spinOnce: boolean;

	hasSpun: boolean = undefined;
	spinning$: Observable<boolean>;
	onLine$ = onLine$;

	ngOnInit() {
		this.spinning$ = this.model.isLoading$.pipe(
			map(loading => {
				if (this.spinOnce) {
					if (this.hasSpun) return false;
					if (loading && this.hasSpun === undefined) this.hasSpun = false;
					if (!loading && this.hasSpun === false) this.hasSpun = true;
				}
				return loading;
			}),
			takeUntil(this.destroySubscriptions$)
		)
	}

}
