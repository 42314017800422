import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineToList, StoreLoader } from '@aston/foundation';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { CommentBearing } from 'apps/debtor-portal/src/app/comments-module/enums';
import { AccountingStoreActions, AccountingStoreSelectors } from 'apps/debtor-portal/src/app/root-store/accounting-store';
import { AppStoreSelectors } from 'apps/debtor-portal/src/app/root-store/app-store';
import { CommentsStoreActions } from 'apps/debtor-portal/src/app/root-store/comments-store';
import { ListActionHandler } from 'apps/debtor-portal/src/app/shared-module/handlers';

import { AccountingListSortProp } from '../../enums';
import { IAccountingItemList, IAccountingListItem } from '../../models';

@Component({
	selector: 'dunning-accounting-page',
	templateUrl: './dunning-accounting-page.component.html',
	standalone: false
})
export class DunningAccountingPageComponent implements OnInit {

	payLink$ = this.store.select(AppStoreSelectors.selectTenant).pipe(map(t => t.paymentLinkClipUrl));
	currency$ = this.store.select(AppStoreSelectors.selectTenant).pipe(map(t => t.currencyCodeInvoicing));

	kpis$ = this.store.select(AccountingStoreSelectors.selectDebtorKPIs);

	loader = new StoreLoader(
		this.store.select(AccountingStoreSelectors.selectDebtorKPIsIsLoading),
		this.store.select(AccountingStoreSelectors.selectDebtorKPIsError));

	list$ = combineToList<IAccountingItemList, IAccountingListItem, AccountingListSortProp>(
		this.store.select(AccountingStoreSelectors.selectAccounting),
		this.store.select(AccountingStoreSelectors.selectAccountingPaging));

	routeParams$ = this.route.params;

	listHandler: ListActionHandler;

	constructor(
		protected store: Store,
		protected route: ActivatedRoute,
	) {}

	ngOnInit() {
		this.store.dispatch(AccountingStoreActions.LoadDebtorKPIsRequest());

		this.listHandler = new ListActionHandler()
			.withPagingDispatcher(this.dispatchPagingAction.bind(this));

		this.routeParams$.pipe(take(1)).subscribe(params => {
			const { id: dunningActionId } = params;
			this.store.dispatch(AccountingStoreActions.LoadAccountingRequestForDunningActionId({ dunningActionId }))
		})
	}

	dispatchPagingAction(props) {
		this.store.dispatch(AccountingStoreActions.UpdateAccountingSettings(props));
	}

	onPay() {
		this.store.dispatch(AccountingStoreActions.OpenPaymentModal())
	}

	onDiscuss() {
		this.routeParams$.pipe(take(1)).subscribe(params => {
			const { id: dunningActionId } = params;
			const bearing = CommentBearing.DunningAction;

			this.store.dispatch(CommentsStoreActions.DiscussModalOpen({ dunningActionId, accountingDocumentId: null, bearing }))
		})
	}
}
